// Libraries
import isString from 'lodash/isString';

// Redux
import { setSubmissionStatus } from 'state/ducks/ideation';

const IMAGE_SIZE_LIMIT_MB = 2;
const PREFIX = 'ideation.errors.idea';

export const formValidations = Object.freeze({
  title: {
    required: `${PREFIX}.title.required`,
    maxLength: { value: 80, message: `${PREFIX}.title.max_length` },
    minLength: { value: 2, message: `${PREFIX}.title.min_length` },
  },
  description: {
    maxLength: { value: 500, message: `${PREFIX}.description.max_length` },
    minLength: { value: 0, message: `${PREFIX}.description.min_length` },
  },
  image: {
    validate: {
      size: ([file]) => {
        if (!file) return true;
        if (file.size < IMAGE_SIZE_LIMIT_MB * 1000 * 1000) return true;
        return `${PREFIX}.image.size`;
      },
    },
  },
  consent: { required: `${PREFIX}.consent.must_consent` },
});

const userErrors = ['USER_BANNED', 'USER_SUSPENDED'];
const createStringErrors = [['title', 'TITLE_TAKEN', `${PREFIX}.title.taken`]];
const createErrors = [
  ['title', 'TOXIC', `${PREFIX}.title.toxic`],
  ['description', 'TOXIC', `${PREFIX}.description.toxic`],
  ['image', 'TOXIC', `${PREFIX}.image.toxic`],
  ['image', 'INVALID_IMAGE_TYPE', `${PREFIX}.image.invalid_mime_type`],
];

export function processUserError(error, dispatch, onError) {
  const bodyError = error.body?.errors;

  if (userErrors.includes(bodyError)) {
    dispatch(setSubmissionStatus({ status: bodyError }));
    onError?.();
    throw bodyError;
  }
}

export function processCreateError(error) {
  const bodyError = error.body?.errors;
  const errors = [];

  if (!bodyError) return errors;

  if (isString(bodyError)) {
    for (const [field, type, message] of createStringErrors) {
      if (bodyError.includes(type)) errors.push([field, { type, message }]);
    }

    return errors;
  }

  for (const [field, type, message] of createErrors) {
    if (bodyError[field]?.includes(type)) errors.push([field, { type, message }]);
  }

  return errors;
}
