// Libraries
import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

// State
import { getQuestionInputsMap, pushResponseEntry } from 'state/ducks/engagement';
import { deleteEntry, patchEntry, postEntry } from 'state/helpers/responses';

// Utils
import { confirmValidMarkers } from 'engagement/utils/helpers';

// Components
import GeolocationAnnotationInput from 'engagement/components/inputs/GeolocationAnnotationInput';

export function GeolocationQuestion(props) {
  const { question, currentQuestionValues, dispatchUpdateQuestionValue } = props;
  const questionValue = currentQuestionValues?.[question.id] || {};
  const inputsMap = useSelector(getQuestionInputsMap);
  const dispatch = useDispatch();
  const dispatchDebounced = useCallback(debounce(dispatch, 300), []);
  const { centre, zoom } = question.questionGeolocation;

  const markers = useMemo(() => {
    return Object.entries(questionValue).reduce((accumulator, [inputId, { value }]) => {
      const validMarkers = confirmValidMarkers(value, 'lat', 'lng');
      accumulator[inputId] = validMarkers.map(({ guid, lat, lng, comment }) => ({
        guid,
        position: [lat, lng],
        comment,
      }));

      return accumulator;
    }, {});
  }, [questionValue]);

  const getOnChangeHandler = (inputId) => (markersMap, meta) => {
    const newValue = Object.entries(markersMap).map(([guid, marker]) => {
      const { position, comment } = marker;
      const [lat, lng] = position;
      return { guid, lat, lng, comment };
    });

    const newQuestionValue = { [inputId]: { value: newValue } };
    dispatchUpdateQuestionValue(question.id, newQuestionValue, true);

    if (meta.removed) {
      const entry = deleteEntry({
        questionUuid: question.uuid,
        inputUuid: inputsMap[inputId].uuid,
        responseUuid: meta.removed.uuid,
        inputAnswer: meta.removed.position.join(','),
        comment: meta.removed.comment,
      });
      dispatch(pushResponseEntry(entry));
      return;
    }

    const lastMarkerUuid = meta.created || meta.updated;
    const data = {
      questionUuid: question.uuid,
      inputUuid: inputsMap[inputId].uuid,
      responseUuid: lastMarkerUuid,
      inputAnswer: markersMap[lastMarkerUuid].position.join(','),
      comment: markersMap[lastMarkerUuid].comment,
    };

    if (meta.created) dispatch(pushResponseEntry(postEntry(data)));
    else dispatchDebounced(pushResponseEntry(patchEntry(data)));
  };

  return (
    <div className="geolocation-question">
      {question.inputs.map(({ id }) => (
        <GeolocationAnnotationInput
          key={id}
          initialMarkers={markers[id]}
          onChange={getOnChangeHandler(id)}
          initialPosition={[centre.x, centre.y]}
          initialZoom={zoom}
        />
      ))}
    </div>
  );
}

GeolocationQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    uuid: PropTypes.string.isRequired,
    questionGeolocation: PropTypes.shape({
      centre: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
      }).isRequired,
      zoom: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  currentQuestionValues: PropTypes.shape({}),
  dispatchUpdateQuestionValue: PropTypes.func.isRequired,
};

GeolocationQuestion.defaultProps = {
  currentQuestionValues: {},
};

export default memo(GeolocationQuestion);
